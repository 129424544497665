.tba {
  text-align: center;
  font-size: 40px;
  min-height: 53vh;
  font-weight: bold;
  margin-top: 50px; }

.publications {
  margin-top: 64px;
  margin-bottom: 40px;
  margin-left: 310px;
  margin-right: 310px;
  .title {
      color: #2CB5C8;
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 12px;
      margin-top: 40px; }
  .publications_journals {
    p {
      font-size: 16px; } }
  .publications_conferences {
    p {
      font-size: 16px; } }
  .publications_invited_speeches {
    margin-bottom: 35px; } }

@media (max-width: 1199.98px) {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px; }
  .publications {
    margin-top: 40px;
    margin-bottom: 40px;
    margin-left: 20px;
    margin-right: 20px; } }
