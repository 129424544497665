.team {
  background-position: top right;
  background-repeat: no-repeat;
  .team_logo {
    text-align: center;
    display: block;
    margin: 56px 0 96px;
    img {
      width: auto; } }
  .team_profressor_bar {
    height: 56px;
    background: linear-gradient(101.79deg, #2CB5C8 0%, #63D1DF 100%);
    border-radius: 4px;
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 64px 113px;
    .profressor_group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .profressor_icon {
        width: 22px;
        height: 22px;
        margin-right: 10px; }
      .profressor_label {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        height: 30px;
        line-height: 30px;
        color: #ffffff; } }
    .star_icon {
      width: 22px;
      height: 22px; } }
  .team_member {
    margin: 40px 113px;
    .member_list_title {
      font-family: 'Quicksand';
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 24px;
      color: #2CB5C8; }
    .member_list_line {
      width: 176px;
      height: 2px;
      background-color: #2CB5C8;
      margin: 32px 0; }
    .member_list_details {
      display: flex;
      flex-wrap: wrap;
      .team_member_item_member {
        width: 267px;
        margin: 0 32px 32px 0;
        text-align: left;
        p {
          font-family: 'Quicksand';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #666666;
          margin-top: 4px; }
        .person_name {
          font-weight: bold;
          // padding: 20px
          font-size: 18px;
          margin-top: 16px;
          line-height: 30px;
          color: #2CB5C8; }
        img {
          width: 100%;
          transition: .5s ease;
          border-radius: 4px; } } } }
  .team_student_bar {
    height: 56px;
    background: linear-gradient(101.79deg, #FFB067 0%, #F7D971 100%);
    border-radius: 4px;
    padding: 10px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 64px 113px;
    .student_group {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .student_icon {
        width: 22px;
        height: 22px;
        margin-right: 10px; }
      .student_label {
        font-family: 'Quicksand';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        height: 30px;
        line-height: 30px;
        color: #ffffff; } }
    .star_icon {
      width: 22px;
      height: 22px; } } }

@media (max-width: 1199.98px) {
  .team {
    padding: 20px;
    .team_profressor_bar {
      margin: 40px 20px; }
    .team_student_bar {
      margin: 40px 20px; }
    .team_logo img {
      width: 100%;
      margin-bottom: 36px; }
    .team_details {
      display: block;
      .team_details_image_wrapper {
        display: block;
        width: auto;
        margin-bottom: 24px;
        .team_details_image {
          width: 100%;
          height: auto; } }
      .team_details_description_wrapper {
        width: auto;
        display: block;
        margin-bottom: 24px;
        .team_details_description {
          width: auto; } } }

    .team_vietnam {
      .team_details_description_wrapper {
        margin-left: 0; } }

    .team_japan {
      display: flex;
      flex-direction: column;
      .team_details_image_wrapper {
        order: 1;
        justify-content: unset;
        margin-bottom: 24px; }
      .team_details_description_wrapper {
        order: 2;
        justify-content: unset;
        margin-bottom:  24px; } }

    .team_member {
      margin: 0;
      .member_list_title {
        font-size: 24px; }
      .member_list_line {
        height: 2px; }
      .member_list_details {
        .team_member_item_member {
          padding: 0px 10px 0 0px;
          margin: 0;
          width: 50%;
          &:nth-child(2) {
            padding: 0px 0px 0px 10px; }
          a {
            font-size: 16px; } } } } } }
