.acknowledge {
    background-position: bottom left;
    background-repeat: no-repeat;
    padding: 64px 210px;

    .logos {
        width: 100%;
        display: flex;
        flex-direction: column;
        .row {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 24px;
            .logo {
                width: 240px;
                height: 120px; } } }
    .content {
        width: 100%;
        p {
            margin-bottom: 24px;
            font-family: 'Quicksand';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            text-align: justify;
            color: #666666; } }

    img {
        width: 100%;
        height: 364px; } }

@media (max-width: 1199.98px) {
    .acknowledge {
        padding: 40px 20px;

        .logos {
            .row {
                margin-bottom: 0px;
                .logo {
                    width: 158px;
                    height: 89px;
                    margin: 20px; } } } } }

