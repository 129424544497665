.home_header {
  height: 624px;
  padding: 75px 80px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #2A82C4;
  width: 100%;

  .home_title {
    text-align: center;
    font-weight: bold;
    font-family: 'Quicksand';
    font-size: 96px;
    line-height: 120px;
    color: white;
    margin-top: 150px; }

  .home_subtitle {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Quicksand';
    line-height: 32px;
    color: white;
    margin-top: 12px; } }

.home {
  display: flex;
  background: #ffffff;
  min-height: 85vh;
  .home_content {
    width: 50%;
    padding: 53px 113px;
    margin: 24px;
    margin-top: 53px;
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .explore {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-left: -40px;
      img {
        width: 283px;
        height: 64px; } }
    .home_content_title {
      font-size: 24px;
      font-family: 'Quicksand';
      line-height: 32px;
      font-weight: normal;
      color: #2CB5C8;
      margin-bottom: 28px; }
    .home_content_line {
      width: 72px;
      height: 2px;
      background-color: #2CB5C8;
      border-radius: 4px;
      margin-bottom: 16px; }
    .home_content_description {
      font-weight: 500;
      text-align: justify;
      font-size: 13px;
      font-family: 'Quicksand';
      line-height: 20px;
      color: #666666;
      margin-bottom: 40px; }
    .home_button_explore {
      padding: 10px 20px 10px 24px;
      color: #FFFFFF;
      font-size: 20px;
      font-family: 'Quicksand';
      line-height: 30px;
      text-align: center;
      font-weight: bold;
      background: linear-gradient(101.79deg, #2CB5C8 0%, #63D1DF 100%);
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      display: inline-flex;
      align-items: center;
      text-decoration: none;
      span {
        margin-left: 27px;
        margin-right: 16px; }
      img {
        width: auto; } } }
  .home_background_wrapper {
    margin: 24px;
    margin-top: 53px;
    padding: 78px 60px;
    display: flex;
    justify-content: center;
    width: 50%;
    .home_background img {
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 600px;
      height: 600px; } } }


@media (max-width: 1199.98px) {
  .home {
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
    .home_content {
      width: 100%;
      order: 2;
      margin: 0;
      text-align: center;
      padding: 0 20px 32px;
      .home_content_title {
        font-size: 16px;
        margin: auto;
        text-align: left; }
      .home_content_line {
        margin-top: 16px;
        margin-bottom: 16px;
        align: left; }
      .home_content_description {
        text-align: justify; }
      .explore {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-left: 15px; } }

    .home_background_wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      .home_background {
        img {
          padding: 20px 98px;
          width: auto;
          height: 432px;
          order: 1; } } } } }

