* {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: 'Quicksand', sans-serif; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

img {
  width: 100%;
  height: auto; }

.block_title {
  padding: 75px 80px;
  font-size: 40px;
  line-height: 50px;
  text-align: center;
  font-weight: 500;
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #2CB5C8; }

a, a:visited, a:hover {
  color: #2CB5C8;
  text-decoration: none;
  &:hover {
    text-decoration: underline; } }
